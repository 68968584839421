// Define styles for the .country_table class
.country_table {
    width: 100%;
    border-collapse: collapse;

    // Styles for the <thead> element
    thead {
        background-color: #f0f0f0;
        font-weight: bold;

        // Styles for <tr> elements within <thead>
        tr {
            // Styles for <th> elements within <tr>
            th {
                padding: 10px;
                border-bottom: 1px solid #ccc;
                border-left: 1px solid #ccc;
                border-right: 1px solid #ccc;
                text-align: center;
                position: sticky;
                top: 0;
                background-color: #f0f0f0; // Ensure background color matches the header background
                z-index: 2; // Ensure header stays above table content
                box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); // Add shadow to give visual separation
            }
        }
    }

    // Styles for the <tbody> element
    tbody {
        // Styles for <tr> elements within <tbody>
        tr {
            // Styles for <td> elements within <tr>
            td {
                padding: 10px;
                border: 1px solid #ccc;
            }
        }
    }
}
