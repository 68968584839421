@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// Variables
$background-image: './assets/images/bgimg.png';
$font-family: 'Barlow';

// Mixins
@mixin full-screen {
  height: 100vh;
  width: 100%;
}

.screen_main {
  @include full-screen;
  background: url(#{$background-image}) no-repeat center center;
  background-size: cover;
  font-family: $font-family;
  /* background-attachment: fixed;
  overflow-y: auto; */
  /* position: relative; */
}
