
.country-table-layout-main {
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 135px); // Adjust as needed
  }
  
  .country-table-layout-table-main {
    width: 90%;
    background-color: white;
    border-radius: 10px;
    padding: 12px;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  
  .scrollable-content {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    padding-right: 10px;
  
    &::-webkit-scrollbar {
      width: 8px; // Width of the scrollbar
    }
  
    &::-webkit-scrollbar-track {
      background: #f1f1f1; // Track color
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #888; // Thumb color
      border-radius: 4px; // Rounded corners
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555; // Hover color
    }
  }
  